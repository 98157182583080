import React, { useEffect, useState } from 'react'
import whaleF from 'shared/assets/images/whaleF.png'
import cls from './LimitationPage.module.scss'
import { Text, TextSize, TextWeight } from 'shared/ui/Text/Text'
import { useDispatch } from 'react-redux'
import { navActions } from 'entities/NavMenu'
import { Button } from 'shared/ui/Button'
import { ColorEnum } from 'shared/ui/Button/ui/Button'
import { useNavigate } from 'react-router-dom'

export const LimitationPage = (props) => {
    const { limitationTime } = props

    const [timeleft, setTimeLeft] = useState(getRemainingTime(limitationTime))

    const navigate = useNavigate()

    const isPremiumPageShowed = localStorage.getItem('isPremiumPageShowed')

    useEffect(() => {
        if (!isPremiumPageShowed) {
            navigate('/premium')
            localStorage.setItem('isPremiumPageShowed', true)
        }
    }, [])

    const toPremium = () => navigate('/premium')

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(getRemainingTime(limitationTime))
        }, 1000)

        return () => clearInterval(interval)
    }, [limitationTime])

    function getRemainingTime(timestamp) {
        const now = Date.now()
        const endTime = timestamp + 12 * 60 * 60 * 1000
        let diff = endTime - now

        if (diff <= 0) return '00:00:00'

        const hours = Math.floor(diff / (1000 * 60 * 60))
        diff %= 1000 * 60 * 60
        const minutes = Math.floor(diff / (1000 * 60))
        diff %= 1000 * 60
        const seconds = Math.floor(diff / 1000)

        return [hours, minutes, seconds]
            .map((unit) => unit.toString().padStart(2, '0'))
            .join(':')
    }

    return (
        <div className={cls.wrapper}>
            <img className={cls.img} src={whaleF} alt="whaleF" />
            <Text
                className={cls.title}
                weight={TextWeight.BOLD}
                size={TextSize.XL}
            >
                На сегодня всё
            </Text>
            <Text className={cls.text}>
                Заходи завтра! Мечты появятся через <b>{timeleft}</b>
            </Text>
            <Button
                className={cls.button}
                color={ColorEnum.ORANGE}
                text={'Снять ограничение'}
                onClick={toPremium}
            />
        </div>
    )
}
