import cls from './PremiumPage.module.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { navActions } from 'entities/NavMenu'
import React from 'react'
import logo from 'shared/assets/icons/LogoPremium.png'
import chevron from 'shared/assets/icons/chevron-left-24.svg'
import background from 'shared/assets/images/premiumBackground.png'
import {
    Text,
    TextAlign,
    TextColor,
    TextSize,
    TextWeight,
} from 'shared/ui/Text/Text'
import { Button } from 'shared/ui/Button'
import { ColorEnum } from 'shared/ui/Button/ui/Button'

export const PremiumPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onRedirect = () => {
        window.open('https://t.me/tribute/app?startapp=srzU')
    }

    const onBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        dispatch(navActions.setVisible(false))
    }, [])

    return (
        <div className={cls.scrollable}>
            <div className={cls.premiumPage}>
                <Button onClick={onBack} className={cls.back}>
                    <img
                        style={{ pointerEvents: 'none', width: '24px' }}
                        src={chevron}
                        alt="chevron"
                    />
                </Button>
                <div className={cls.ImageContainer}>
                    <img className={cls.Image} alt="TagLife" src={logo} />
                </div>
                <div className={cls.titleContainer}>
                    <Text size={TextSize.XL} weight={TextWeight.BOLD}>
                        TagLife
                    </Text>
                    <Text
                        color={TextColor.ORANGEGRADIENT}
                        size={TextSize.XL}
                        weight={TextWeight.BOLD}
                    >
                        Premium
                    </Text>
                </div>
                <Text className={cls.Text}>
                    Безлимитный доступ к лучшим идеям и мечтам
                </Text>
                <div className={cls.privilegesContainer}>
                    <div className={cls.privelege}>
                        ⭐ Безлимитные мечты в ленте
                    </div>
                    <div className={cls.privelege}>
                        ⭐ Секретный TagLife чат
                    </div>
                    <div className={cls.privelege}>⭐ Твоё имя в титрах</div>
                </div>
                <Text
                    className={cls.footerText}
                    align={TextAlign.LEFT}
                    weight={TextWeight.REG}
                >
                    Поддерживая нас, ты становишься частью TagLife движения —
                    помогать осуществлять мечты 🌟
                </Text>
                <Button
                    color={ColorEnum.ORANGE}
                    className={cls.Button}
                    onClick={onRedirect}
                >
                    <div className={cls.buttonWrapper}>
                        <Text weight={TextWeight.SBOLD}>
                            Подписаться от 49₽ в месяц
                        </Text>
                        <Text size={TextSize.XS} weight={TextWeight.SBOLD}>
                            (при оплате за год)
                        </Text>
                    </div>
                </Button>
            </div>
            <img className={cls.background} src={background} alt="background" />
            <div className={cls.gradient} />
        </div>
    )
}
